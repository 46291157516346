<template>
  <div
    :class="header.isFullscreen ? 'fullscreen' : ''"
    :style="leftBarWidth"
    class="form-page"
    v-loading="loading"
  >
    <el-container class="layout">
      <!-- 顶部菜单栏 -->
      <el-header class="menu-bar" style="height: 54px">
        <page-header-content @header-click="handleHeaderClick" />
      </el-header>
      <el-container class="workspace">
        <div
          class="workspace-left"
          style="width: auto; max-width: 50%; min-width: var(--left-barwidth)"
        >
          <!-- 左侧菜单 -->
          <div class="resize-bar" />
          <div class="resize-line" />
          <div class="resize-save">
            <!-- 左侧菜单 -->
            <page-left
              :componentsList="componentList"
              v-model="main.selected"
            />
          </div>
        </div>
        <el-main class="workspace-middle">
          <page-middle
            :bottomBtn="main.bottomBtn"
            :list="main.componentList"
            :page="main.page"
            @update-list="updateList"
            ref="pageMiddle"
            v-model="main.selected"
          />
        </el-main>
        <el-aside class="workspace-right" style="width: 360px">
          <page-right
            :bottomBtn="main.bottomBtn"
            :list="main.componentList"
            :page="main.page"
            :selected="main.selected"
            @update-list="updateList"
          />
        </el-aside>
      </el-container>
    </el-container>
    <PreviewCodeDialog v-model="showPreviewqrCode" :data="qrCodeData" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import PageMiddle from '../../components/OrgFrame/Page/PageMiddle'
let library = require('../../assets/jsons/component-library.json')
const defaultList = require('../../assets/jsons/default-data.json').default
let { page, bottomBtn } = JSON.parse(JSON.stringify(library))

import {
  saveField,
  allField,
  templateField,
  saveTemplate,
  getPreviewCode,
} from '../../api/org-frame/custom-form'
import PageHeaderContent from '../../components/OrgFrame/Page/PageHeaderContent'
import PageLeft from '../../components/OrgFrame/Page/PageLeft'
import PageRight from '../../components/OrgFrame/Page/PageRight'
import PreviewCodeDialog from '../../components/OrgFrame/Page/PreviewCodeDialog'
import { resetGlobal } from '../../api/org-frame/add-frame'

export default {
  components: {
    PageMiddle,
    PageRight,
    PageLeft,
    PageHeaderContent,
    PreviewCodeDialog,
  },
  data() {
    return {
      loading: false,
      header: {
        isFullscreen: true,
      },
      pageDetail: {
        id: 0,
        config: {
          page: {},
          list: defaultList,
        },
      },
      main: {
        selected: 'page',
        page: JSON.parse(JSON.stringify(page)),
        bottomBtn: JSON.parse(JSON.stringify(bottomBtn)),
        componentList: [],
      },
      componentList: [],
      selectArr: [],
      qrCodeData: {},
      showPreviewqrCode: false,
      leftBarWidth: '--left-barwidth: ' + '285px' + '; ',
    }
  },
  computed: {
    ...mapState('formPage', ['library']),
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    pageSet() {
      return this.$route.params.pageset
    },
  },
  watch: {
    'pageDetail.id'(val) {
      if (val === '0') {
        this.getAllDetail()
      } else {
        this.getTemplateField(val)
      }
    },
    componentList: {
      deep: true,
      handler(val) {
        let componentList = []
        let selected = []
        val.forEach((el) => {
          if (el.is_show) {
            componentList.push(el)
            selected.push(el.id)
          }
        })
        this.main.componentList = componentList
        this.selectArr = selected
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.pageDetail.id = this.$route.params.id
    })
    // getIndexData()
    //   .then((res) => {
    //     this.$store.commit(
    //       "formPage/setParamsData",
    //       res.data.find((el) => el.platform === "mp-weixin")
    //     );
    //   })
    //   .catch((err) => {});
    // this.getSystemPageConfig();
    /* 模拟接口请求 */
    // setTimeout(() => {
    //   console.log(defaultList);
    //   this.pageDetail.id = 111;
    // }, 400);

    this.$store.commit(
      'formPage/setLibrary',
      JSON.parse(JSON.stringify(library))
    )
  },
  methods: {
    // 获取页面设置和底部按钮设置
    // getSystemPageConfig() {
    //   this.loading = true;
    //   systemPageConfig()
    //     .then((res) => {
    //       const { data } = res;
    //       this.main.page = data.config.page;
    //       this.main.bottomBtn = data.config.bottomBtn;
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
    // 获取全局页面配置详情
    getAllDetail() {
      this.loading = true
      allField({
        pageset: this.pageSet,
      })
        .then((res) => {
          const { data } = res
          // TODO: 接口获取完数据后执行，判断方式如：watch 'pageDetail.id'
          let componentList = []
          let listData = data.length
            ? data
            : data.configs && data.configs.length
            ? data.configs
            : defaultList
          listData.forEach((item, index) => {
            componentList.push({
              ...item,
              extra: {
                timestamp: new Date().getTime() + index * index,
              },
            })
            this.updateCount(item.field)
          })
          this.main.componentList = this.initFormat(componentList)

          const _page = JSON.parse(JSON.stringify(page.props.page_config))
          _page.addresses.push('')

          if (this.tenantVersion === 'school' && data.page_config) {
            this.main.page.props.page_config = data.page_config
          } else if (data.page_config) {
            this.main.page.props.page_config = this.$mergeModel(
              _page,
              data.page_config
            )
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取架构表单配置详情
    getTemplateField(id) {
      this.loading = true
      templateField({ id, pageset: this.pageSet })
        .then((res) => {
          const { data } = res
          let componentList = []
          data.global.forEach((el) => {
            if (el.is_show) {
              // 默认不能删除的表单字段，用户无法跳过填写
              componentList.push(el)
            }
          })
          this.main.componentList = this.initFormat(componentList)
          this.componentList = data.global
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    /**
     * 恢复为默认的配置（即全局配置）
     */
    setDefaultList() {
      this.$confirm(
        '是否将该架构的入会申请表单恢复与全局申请表单一致？',
        '提示',
        { type: 'warning' }
      )
        .then(() => {
          this.loading = true

          resetGlobal({
            id: this.pageDetail.id,
            pageset: this.pageSet,
          })
            .then(({ data, msg }) => {})
            .catch(() => {})
            .finally(() => {
              location.reload()
            })
        })
        .catch(() => {})
    },
    /**
     * 修正错误数据
     */
    initFormat(list) {
      const _list = JSON.parse(JSON.stringify(list))
      _list.forEach((li) => {
        if (li.field === 'description') {
          li.props.normal.inputType = 'textarea' // 适配"描述内容"
        }
      })
      return _list
    },
    async handleHeaderClick(key) {
      this.main.selected = 'page'

      switch (key) {
        case 'reset': {
          // TODO: 恢复默认（即全局的配置）
          this.setDefaultList()
          break
        }
        case 'save': {
          try {
            await this.switchPage()
          } catch (e) {
            console.log(e)
          }
          break
        }
        case 'fullscreen':
          // this.header.isFullscreen = !this.header.isFullscreen;
          break
        case 'preview': {
          try {
            await this.switchPage(true)
            const saveData = { ...this.main }
            const fieldData = {
              pageset: this.pageSet,
            }
            fieldData.config = JSON.stringify(saveData.componentList)
            getPreviewCode(fieldData)
              .then((res) => {
                this.qrCodeData = res.data
                this.showPreviewqrCode = true
                this.loading = false
              })
              .catch(() => {
                this.loading = false
              })
          } catch (e) {
            console.log(e)
          }
          break
        }
        default:
      }
    },
    /**
     * 更新组件列表
     */
    updateList(arr) {
      this.main.componentList = arr
    },
    // 保存
    switchPage(isPreview = false) {
      this.loading = true
      if (this.pageDetail.id == 0) {
        const saveData = { ...this.main }
        const fieldData = {}
        let flag = false
        // 表单标题查重
        saveData.componentList.forEach((el1, i) => {
          if (flag) return

          if (el1.field === 'member_type') {
            el1.props.normal.options.forEach((op) => {
              if (op.is_open && op.option_name === '' && !flag) {
                this.main.selected = i
                this.$refs.pageMiddle.errFormConfig(i)
                this.$message.error('会员类型选项文本不能为空')
                this.loading = false
                flag = true
              }
            })
          }
          saveData.componentList.forEach((el2, j) => {
            if (
              (el2.props.normal.label || el2.props.title) ===
                (el1.props.normal.label || el1.props.title) &&
              i !== j
            ) {
              if (!flag) {
                this.main.selected = i
                this.$refs.pageMiddle.errFormConfig(i)
                this.$message.error('存在标题重复的表单')
                this.loading = false
                flag = true
              }
            }
          })

          // 表单选项查重、表单选项空白检测
          const my_options =
            el1.options !== undefined
              ? el1.options
              : el1.props?.normal?.options || []
          if (my_options.length > 0) {
            if (
              my_options.filter((el) => {
                return typeof el === 'string' ? !el : !(el.text || el.title)
              }).length > 0
            ) {
              // 有空白选项
              const formItemLabel = el1.name || el1.props?.normal?.label
              this.main.selected = i
              this.$refs.pageMiddle.errFormConfig(i)
              this.$message.error(formItemLabel + '选项文本不能为空')
              this.loading = false
              flag = true
            } else {
              const rawLen = my_options.length
              const setLen = Array.from(
                new Set(
                  my_options.map((el) =>
                    typeof el === 'string' ? el : (el.text || el.title)
                  )
                )
              ).length
              if (rawLen === setLen) {
                // 没有重复
              } else {
                const formItemLabel = el1.name || el1.props?.normal?.label
                this.main.selected = i
                this.$refs.pageMiddle.errFormConfig(i)
                this.$message.error(formItemLabel + '选项不能重复')
                this.loading = false
                flag = true
              }
            }
          }
        })
        if (flag) {
          // 查重不通过，走 catch
          return Promise.reject()
        }
        // 预览不需要保存，仅查重
        if (isPreview) return Promise.resolve()
        fieldData.pageset = this.pageSet
        fieldData.config = JSON.stringify(saveData.componentList)
        fieldData.page_config = JSON.stringify(this.main.page.props.page_config)
        saveField(fieldData)
          .then((res) => {
            if (!isPreview) {
              this.$message.success(res.msg)
              this.loading = false
            }
            return Promise.resolve()
          })
          .catch(() => {
            this.loading = false
            return Promise.reject()
          })
      } else {
        if (isPreview) return Promise.resolve()
        const data = {
          id: this.pageDetail.id,
          cyc_apply_template_id: this.selectArr,
          pageset: this.pageSet,
        }
        saveTemplate(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.loading = false
            return Promise.resolve()
          })
          .catch(() => {
            this.loading = false
            return Promise.reject()
          })
      }
    },
    /* 保存页面设置和底部按钮 */
    // savePageConfig() {
    //   const pageData = {
    //     page: this.main.page,
    //     bottomBtn: this.main.bottomBtn,
    //   };
    //   savePage({ config: pageData })
    //     .then((res) => {
    //       if (this.pageDetail.id == 0) {
    //         this.getAllDetail();
    //       } else {
    //         this.getTemplateField(this.pageDetail.id);
    //       }
    //       this.loading = false;
    //     })
    //     .catch((err) => {
    //       this.loading = false;
    //     });
    // },
    /***
     * 修改组件使用次数
     * @param{String} field 组件标识
     */
    updateCount(field) {
      /* 组件使用次数+1 */
      let newLibrary = JSON.parse(JSON.stringify(this.library))
      const groups = ['base', 'personal', 'other', 'unit']
      groups.forEach((g) => {
        if (newLibrary[g])
          newLibrary[g].forEach((li) => {
            if (field) {
              if (li.field === field) {
                li.extra && li.extra.count++
              }
            } else {
              li.extra.count = 0
            }
          })
      })
      this.$store.commit('formPage/setLibrary', newLibrary)
    },
  },
}
</script>

<style lang="scss">
.form-page {
  overflow-x: auto;
  margin: calc(-#{$space} * 2);
  background-color: #eef2f8;

  /* 全屏 */
  &.fullscreen {
    position: fixed;
    left: calc(#{$space} * 2);
    top: calc(#{$space} * 2);
    height: 100vh;
    width: 100%;
    z-index: 11;

    .workspace {
      height: calc(100vh - 54px);
    }
  }

  .menu-bar {
    background-color: $primary;
  }

  /* 顶部菜单栏下面的一整个容器 */
  .workspace {
    height: calc(100vh - 60px - 50px - 54px);
  }

  .page-left,
  .page-right {
    background-color: white;
  }

  .workspace-left {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    float: left;
  }

  .el-main.workspace-middle {
    padding: 0;
    overflow: hidden;
  }
}

.resize-save {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}

.resize-bar {
  width: var(--left-bar-width);
  min-width: var(--left-bar-width);
  max-width: 100%;
  /*height: inherit;*/
  height: calc(100vh - 54px);
  resize: horizontal;
  cursor: col-resize;
  opacity: 0;
  overflow: scroll;
}

/* 拖拽线 */
.resize-line {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 2px solid #eee;
  border-left: 1px solid #bbb;
  pointer-events: none;
}

.resize-bar:hover ~ .resize-line,
.resize-bar:active ~ .resize-line {
  border-left: 1px dashed #3a76ff;
}

.resize-bar::-webkit-scrollbar {
  width: 200px;
  height: inherit;
}

/* Firefox只有下面一小块区域可以拉伸 */
@supports (-moz-user-select: none) {
  .resize-bar:hover ~ .resize-line,
  .resize-bar:active ~ .resize-line {
    border-left: 1px solid #bbb;
  }
  .resize-bar:hover ~ .resize-line::after,
  .resize-bar:active ~ .resize-line::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 0;
    right: -8px;
    /*background: url(./resize.svg);*/
    /*background-size: 100% 100%;*/
  }
}
</style>
